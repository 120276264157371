import axios from "./index";
import MockApi from "./mockApi";
import {API} from "./env";
import RateLimiter from "../utils/RateLimiter";

class AuthApi {

  static Auth = (aPhoneNumber, employeeId, companyExternalId) => {
      if(MockApi.IsActive) {
          return MockApi.Auth();
      }

      if (!RateLimiter.canMakeRequest('auth')) {
          const minutesLeft = RateLimiter.getTimeUntilNextRequest('auth');
          return Promise.reject({
              response: {
                  status: 429,
                  data: {
                      message: `Too many attempts. Please try again in ${minutesLeft} minutes.`
                  }
              }
          });
      }

      let data = JSON.stringify({
          "phoneNumber": aPhoneNumber,
          "id": employeeId,
          "companyExternalId": companyExternalId
      });

      let config = {
          method: 'post',
          headers: {
              'Content-Type': 'application/json'
          },
          data : data
      };
      return axios.post(`${API}/auth`, data, config);
  };

    static Login = (otpToken) => {
        if(MockApi.IsActive) {
            return MockApi.Login();
        }

        if (!RateLimiter.canMakeRequest('login')) {
            const minutesLeft = RateLimiter.getTimeUntilNextRequest('login');
            return Promise.reject({
                response: {
                    status: 429,
                    data: {
                        message: `Too many attempts. Please try again in ${minutesLeft} minutes.`
                    }
                }
            });
        }

        this.sessionToken = localStorage.getItem("tempSessionToken")

        const data = JSON.stringify({
            "otp": otpToken,
            "sessionToken": this.sessionToken
        });

        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data : data
        };

        return axios.post(`${API}/auth/otp`, data, config);
    };

    static GetUserInfo = (accessToken) => {
        if(MockApi.IsActive) {
            return MockApi.GetUserInfo();
        }

        if(!accessToken || undefined || accessToken.length === 0){
            let userInfo = JSON.parse(localStorage.getItem("user"));
            accessToken = userInfo.token;
        }

        const config = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axios.get(`${API}/user`, config);
    };

    static GetCompanies = () => {

        const config = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        };

        return axios.get(`${API}/companies/logos`, config);
    };


}

export default AuthApi;
