/* eslint-disable */
import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import {
	Box,
	Button,
	Image,
	Input,
	Text,
	Heading,
	Stack,
	VStack,
	useColorMode,
} from '@chakra-ui/react'

import { useTranslation } from 'react-i18next'
import DefaultAuth from 'layouts/auth/Default'
// Assets
import AuthApi from '../../../api/auth'
import FadeTransition from 'components/pageTransition/FadeTransiton'
import { LogEvent } from '../../../analytics/analytics'
import { openWhatsAppSupport } from '../../../utils/Support'
import headSetIcon from '../../../assets/img/icons/headset_mic.png'
import { getMobileOperatingSystem, Mobile } from '../../../utils/DeviceUtils'
import {useAuth} from "../../../auth-context/auth.context";


function SignIn() {
	const [phoneNumber, setPhoneNumber] = useState('') // <-- Default values HERE
	const [employeeId, setEmployeeId] = useState('') // <-- Default values HERE
	const [error, setError] = useState(undefined)
	const { colorMode, toggleColorMode } = useColorMode()
	const history = useHistory()
	const { user } = useAuth();
	const [selectedCompany, setSelectedCompany] = useState("");
	const [companies, setCompanies] = useState([]);

	useEffect(() => {
		LogEvent('auth_view')
		if (colorMode === 'light') {
			toggleColorMode()
		}
		if (user) {
			history.push('/rtl-default')
		}

		// Fetch companies
		const fetchCompanies = async () => {
			try {
				const response = await AuthApi.GetCompanies();
				const temp = Object.entries(response.data);
				setCompanies(temp);
			} catch (err) {
				console.error("Failed to fetch companies:", err);
			}
		};

		fetchCompanies();

	}, [])

	useEffect(() => {
		console.log("Selected company:", selectedCompany);
	}, [selectedCompany]);
	const [show, setShow] = React.useState(false)
	const onPhoneNumberInput = (e) => {
		if (e.target.value.length > e.target.maxLength)
			e.target.value = e.target.value.slice(0, e.target.maxLength)
	}
	const handleClick = () => setShow(!show)
	const login = async (event) => {
		if (event) {
			event.preventDefault()
		}
		if (phoneNumber === '') {
			return setError(t('login_1_textfield_missing_error_text'))
		}
		if (employeeId === '') {
			return setError(t('login_1_textfield2_missing_error_text'))
		}
		if (selectedCompany === "") {
			return setError("אנא בחר חברה");
		}
		// setButtonText("מתחבר");

		try {
			let response = await AuthApi.Auth(phoneNumber, employeeId, selectedCompany)
			if (response.data && response.data.success === false) {
				//setButtonText("Sign in");
				return setError(response.data.msg)
			}
			return goToOtp(response)
		} catch (err) {
			console.log(err)

			if (err && err.response) {
				switch (err.response.status) {
					case 429:
						return setError(err.response.data.message)
					case 401:
						return setError(t('login_error_no_user'))
					case 400:
						return setError('אנא הכנס מספר טלפון תקין')
					case 402:
					{
						const code = err.response.data.code;
						// underage
						if(code === 111) {
							return setError(t('login_failed_underage'))
						} else if(code === 110) {
							// UserNoDetailsError
							return setError(t('login_failed_no_details'))
						} else if(code === 112) {
							// UserNotExists
							return setError(t('login_error_no_user'))
						}
						break;
					}
					// code block
				}
			} else if (err.message) {
				return setError(err.message)
			}
			return setError('שגיאה')
		}
	}

	const goToOtp = async (response) => {
		AuthApi.sessionToken = response.data.response.sessionToken
		localStorage.setItem(
			'tempSessionToken',
			response.data.response.sessionToken,
		)
		return history.push('/auth/otp')
	}
	const mobileOs = getMobileOperatingSystem()

	const { t } = useTranslation()
	return (
		<FadeTransition>
			<DefaultAuth>
				<Stack
					minH={
						mobileOs === Mobile.Android
							? 'calc(100vh - 175px)'
							: 'calc(100vh - 230px)'
					}
				>
					<Stack mt="2">
						<VStack>
							<Heading
								textAlign="center"
								fontFamily="Noto Sans Hebrew, sans-serif"
								textColor={'#2E3136'}
								fontSize="34px"
								fontWeight="bold"
							>
								{t('welcome_to_payro_title')}
							</Heading>
							<Text
								fontFamily="Noto Sans Hebrew, sans-serif"
								textColor={'#2E3136'}
								textAlign="center"
								maxW="32ch"
								mt="22px !important"
								fontSize="18px"
								lineHeight="1.4"
							>
								{t('login_1_message')}
							</Text>
							<h4
								style={{
									fontSize: '.9em',
									color: 'red',
									textAlign: 'center',
									fontWeight: 400,
									transition: '.2s all',
								}}
							>
								{error}
							</h4>
						</VStack>
					</Stack>
					<Stack spacing={'7'} style={{ marginTop: '50px' }}>
						{/* Company Dropdown */}
						<select
							value={selectedCompany}
							onChange={(e) => setSelectedCompany(e.target.value)}
							style={{
								backgroundColor: 'transparent',
								color: '#2E3136',
								fontFamily: 'Noto Sans Hebrew, sans-serif',
								border: '1px solid #ccc',
								borderRadius: '4px',
								padding: '10px',
								width: '100%',
								height: '50px',
								fontSize: '16px',
								appearance: 'none', // Makes it look consistent across devices
								boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
							}}
						>
							<option value="" disabled>
								אנא בחר את מקום העבודה שלך
							</option>
							{companies.map(([key, company]) => (
								<option key={key} value={key}>
									{company.displayName}
								</option>
							))}
						</select>

						<Input
							borderRadius="4px"
							px="3"
							height="50px"
							textAlign="center"
							isRequired={true}
							type="number"
							maxLength="10"
							placeholder={t('login_1_textfield_hint')}
							onInput={onPhoneNumberInput}
							variant="auth"
							_focus={{ borderColor: 'newBrand.500' }}
							fontSize="18px"
							borderColor="secondaryGray.600"
							textColor="#2E3136"
							onChange={(event) => {
								setPhoneNumber(event.target.value)
								setError(undefined)
							}}
						/>
						<Input
							isRequired={true}
							borderRadius="4px"
							_focus={{ borderColor: 'newBrand.500' }}
							px="3"
							height="50px"
							textAlign="center"
							type="number"
							size="lg"
							placeholder={t('login_1_textfield2_hint')}
							variant="auth"
							borderColor={'secondaryGray.600'}
							textColor="#2E3136"
							onChange={(event) => {
								setEmployeeId(event.target.value)
								setError(undefined)
							}}
						/>

						<Button w="100%" onClick={login} variant="newBrand">
							{t('login_1_button_text')}
						</Button>
						<Text pt="1" fontSize="18px" textAlign="center">
							{t('employer_not_offering_payro')}
							<Box
								as="span"
								fontWeight="bold"
								textDecor="underline"
								mr="2"
								onClick={openWhatsAppSupport}
							>
								{t('connect_us_to_your_employer')}
							</Box>
						</Text>
					</Stack>
					<Stack align={'center'} style={{ marginTop: '30px' }}>
						<Button
							variant="greenOutlineBrand"
							rightIcon={<Image src={headSetIcon} />}
							onClick={openWhatsAppSupport}
						>
							{t('customer_support_button')}
						</Button>
					</Stack>
				</Stack>
			</DefaultAuth>
		</FadeTransition>
	)
}

export default SignIn
