class RateLimiter {
    constructor(maxRequests, timeWindowMinutes) {
        this.maxRequests = maxRequests;
        this.timeWindowMs = timeWindowMinutes * 60 * 1000;
        this.requests = new Map(); // Store requests by action type
    }

    canMakeRequest(actionType) {
        const now = Date.now();
        const requestHistory = this.requests.get(actionType) || [];
        
        // Clean up old requests
        const validRequests = requestHistory.filter(
            timestamp => now - timestamp < this.timeWindowMs
        );
        
        // Check if we're under the limit
        if (validRequests.length < this.maxRequests) {
            validRequests.push(now);
            this.requests.set(actionType, validRequests);
            return true;
        }
        
        return false;
    }

    getTimeUntilNextRequest(actionType) {
        const now = Date.now();
        const requestHistory = this.requests.get(actionType) || [];
        if (requestHistory.length === 0) return 0;
        
        const oldestRequest = requestHistory[0];
        return Math.ceil((oldestRequest + this.timeWindowMs - now) / 1000 / 60);
    }
}

export default new RateLimiter(3, 5); // 3 requests per 5 minutes 